document.addEventListener("DOMContentLoaded", function (event) {
  //* Cookies
  const cookiesBlock = document.querySelector(".cookies-block");
  if (cookiesBlock !== null) {
    setTimeout(() => {
      gsap.to(cookiesBlock, {
        css: {
          opacity: 1,
          pointerEvents: "auto",
        },
      });
    }, 2000);
    const cookiesBlockBtn = cookiesBlock.querySelector(".cookies-block__ok");

    function closeCookiesBlock() {
      gsap.to(cookiesBlock, {
        css: {
          opacity: 0,
          pointerEvents: "none",
        },
      });
    }

    function requestCookie(e) {
      e.preventDefault();
      let token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      fetch("/setCookies", {
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": token,
        },
        body: JSON.stringify({
          agree: true,
        }),
      }).then((response) => {
        closeCookiesBlock();
      });
    }

    cookiesBlockBtn.addEventListener("click", requestCookie);
  }

  //* Анимация на загрузкку страницы
  let loadPageTL = gsap.timeline();
  loadPageTL
    .to(document.querySelector(".pre-loader"), {
      duration: 0.1,
      delay: 0.2,
      css: {
        opacity: 0,
        pointerEvents: "none",
      },
    })
    .from(document.querySelector(".header"), {
      opacity: 0,
      duration: 1,
    })
    .from(
      document.querySelector(".main-content"),
      {
        opacity: 0,
        duration: 1,
      },
      "-=0.3"
    )
    .from(
      document.querySelector(".footer"),
      {
        opacity: 0,
        duration: 1,
      },
      "<"
    )
    .from(
      document.querySelector(".nav"),
      {
        opacity: 0,
        duration: 1,
      },
      "<"
    );

  //* Главное меню
  const mainMenuBtn = document.querySelector(".main-menu-btn");
  const submenuBtnBack = document.querySelector(".submenu-back");
  const nav = document.querySelector(".nav");
  const mainMenu = document.querySelector(".main-menu");
  const mainMenuLeftBlock = mainMenu.querySelector(".main-menu__left");
  const mainMenuCenterBlock = mainMenu.querySelector(".main-menu__center");
  const mainMenuRightBlock = mainMenu.querySelector(".main-menu__right");
  const menuLevelOne = document.querySelector("#menu-1");
  const menuLevelOneLinks = menuLevelOne.querySelectorAll('.menu__link');
  const menuLevelTwoLinks = document.querySelectorAll('.submenu_first .submenu__link');
  let currentIdMenuLevelTwo = 0;
  let currentIdMenuLevelThree = 0;

  function openMenu() {
    closeAll('menu');

    if (window.innerWidth >= 1320) {
      const openMenuTimeline = gsap.timeline();

      openMenuTimeline
        .to(mainMenu, {
          duration: 0.01,
          css: {
            opacity: 1,
            pointerEvents: "auto",
          },
        })
        .to(mainMenuRightBlock, {
          duration: 0.25,
          css: {
            transform: "scaleX(1)",
          },
        })
        .to(mainMenuCenterBlock, {
          duration: 0.25,
          css: {
            transform: "scaleX(1)",
          },
        })
        .to(mainMenuLeftBlock, {
          duration: 0.25,
          css: {
            transform: "scaleX(1)",
          },
        })
        .to(".main-menu__logo", {
          opacity: 1,
        })
        .to(
          menuLevelOne,
          {
            opacity: 1,
          },
          "<"
        )
        .to(
          ".menu-info",
          {
            opacity: 1,
          },
          "<"
        );
    } else {
      const openMenuTimeline = gsap.timeline();

      openMenuTimeline
        .to(mainMenu, {
          duration: 0.01,
          css: {
            opacity: 1,
            pointerEvents: "auto",
          },
        })
        .to(mainMenuLeftBlock, {
          x: 0,
          duration: 0.5,
        })
        .to(menuLevelOne, {
          opacity: 1,
          duration: 0.5,
        })
        .to(
          ".nav__btn:not(.nav__btn:first-child)",
          {
            duration: 0.5,
            css: {
              opacity: 1,
              pointerEvents: "auto",
            },
          },
          "<"
        );
    }

    mainMenuBtn.classList.add("main-menu-btn_active");
  }

  function closeMenu() {
    if (window.innerWidth >= 1320) {
      const closeMenuTimeline = gsap.timeline();

      closeMenuTimeline
        .to(".menu-info", {
          duration: 0.1,
          opacity: 0,
        })
        .to(
          menuLevelOne,
          {
            duration: 0.1,
            opacity: 0,
          },
          "<"
        )
        .to(
          ".main-menu__logo",
          {
            duration: 0.1,
            opacity: 0,
          },
          "<"
        )
        .to(mainMenuLeftBlock, {
          duration: 0.25,
          css: {
            transform: "scaleX(0)",
          },
        })
        .to(mainMenuCenterBlock, {
          duration: 0.25,
          css: {
            transform: "scaleX(0)",
          },
        })
        .to(mainMenuRightBlock, {
          duration: 0.25,
          css: {
            transform: "scaleX(0)",
          },
        })
        .to(mainMenu, {
          duration: 0.01,
          css: {
            opacity: 0,
            pointerEvents: "none",
          },
        });
    } else {
      const closeMenuTimeline = gsap.timeline();

      closeMenuTimeline
        .to(mainMenuLeftBlock, {
          x: "100%",
          duration: 0.5,
        })
        .to(
          menuLevelOne,
          {
            opacity: 0,
            duration: 0.5,
          },
          "<"
        )
        .to(
          ".nav__btn:not(.nav__btn:first-child)",
          {
            duration: 0.1,
            css: {
              opacity: 0,
              pointerEvents: "none",
            },
          },
          "<"
        )
        .to(mainMenu, {
          duration: 0.01,
          css: {
            opacity: 0,
            pointerEvents: "none",
          },
        });
    }

    mainMenuBtn.classList.remove("main-menu-btn_active");
  }

  function changeSubmenulevelTwo(e) {
    const target = e.target;
    const id = target.getAttribute('data-id');
    const currentMenu = currentIdMenuLevelTwo === 0 ? null : document.querySelector(`#${currentIdMenuLevelTwo}`);
    const currentMenuLevelThree = currentIdMenuLevelThree === 0 ? null : document.querySelector(`#${currentIdMenuLevelThree}`);
    const nextMenu = document.querySelector(`#${id}`);

    if (currentMenu !== null) {
      currentMenu.classList.remove('submenu_active');
    }
    if (currentMenuLevelThree !== null) {
      currentMenuLevelThree.classList.remove('submenu_active');
    }
    nextMenu.classList.add('submenu_active');

    currentIdMenuLevelTwo = id;
    currentIdMenuLevelThree = 0;
  }

  function changeSubmenulevelThree(e) {
    const target = e.target;
    const id = target.getAttribute('data-id');
    const currentMenu = currentIdMenuLevelThree === 0 ? null : document.querySelector(`#${currentIdMenuLevelThree}`);
    const nextMenu = document.querySelector(`#${id}`);

    if (currentMenu !== null) {
      currentMenu.classList.remove('submenu_active');
    }
    nextMenu.classList.add('submenu_active');

    currentIdMenuLevelThree = id;
  }

  function openMenuLevelTwoMobile(e) {
    const target = e.target;
    const id = target.previousElementSibling.getAttribute("data-id");

    const allSubmenuFirst = document.querySelectorAll(".submenu_first");

    allSubmenuFirst.forEach((element) => {
      element.style.opacity = '0';
      element.style.pointerEvents = 'none';
    });

    gsap.timeline()
      .to(mainMenuCenterBlock, {
        duration: 0.3,
        css: {
          opacity: 1,
          pointerEvents: 'auto',
          transform: 'translateX(0)'
        }
      })
      .to(nav, {
        duration: 0.3,
        css: {
          opacity: 0,
          pointerEvents: 'none'
        }
      }, "<")
      .to(`#${id}`, {
        duration: 0.3,
        css: {
          opacity: 1,
          pointerEvents: 'auto'
        }
      })
  }

  let idToCloseMobile = "";
  let idToOpenMobile = "";
  function closeSubmenu() {
    const sfwDisplay = document.querySelector(".submenu-first-wrapper").style.display;

    if (sfwDisplay === 'none') {
      gsap.timeline()
        .to(idToCloseMobile, {
          duration: 0.3,
          css: {
            opacity: 0,
            pointerEvents: 'none'
          }
        })
        .set('.submenu-second-wrapper', {
          css: {
            display: 'none'
          }
        })
        .set('.submenu-first-wrapper', {
          css: {
            display: 'block'
          }
        })
        .to(idToOpenMobile, {
          duration: 0.3,
          css: {
            opacity: 1,
            pointerEvents: 'auto'
          }
        })
    }
    else {
      gsap.timeline()
        .to(mainMenuCenterBlock, {
          duration: 0.3,
          css: {
            opacity: 0,
            pointerEvents: 'none',
            transform: 'translateX(100%)'
          }
        })
        .to(nav, {
          duration: 0.3,
          css: {
            opacity: 1,
            pointerEvents: 'auto'
          }
        }, "<")
    }
  }

  function openMenuL3Mobile(e) {
    const target = e.target;
    const id = target.previousElementSibling.getAttribute("data-id");
    const closeId = target.parentElement.parentElement.getAttribute("id");

    idToCloseMobile = `#${id}`;
    idToOpenMobile = `#${closeId}`;

    gsap.timeline()
      .to(`#${closeId}`, {
        duration: 0.3,
        css: {
          opacity: 0,
          pointerEvents: 'none'
        }
      })
      .set('.submenu-first-wrapper', {
        css: {
          display: 'none'
        }
      })
      .set('.submenu-second-wrapper', {
        css: {
          display: 'block'
        }
      })
      .to(`#${id}`, {
        duration: 0.3,
        css: {
          opacity: 1,
          pointerEvents: 'auto'
        }
      });
  }

  if (mainMenuBtn !== null && mainMenu !== null) {
    mainMenuBtn.addEventListener("click", (e) => {
      if (e.target.classList.contains("main-menu-btn_active")) {
        closeMenu();
      } else {
        openMenu();
      }
    });
  }

  menuLevelOneLinks.forEach((link) => {
    link.addEventListener('mouseenter', changeSubmenulevelTwo);
    if (window.innerWidth < 1320) {
      const arrowMobile = link.parentElement.querySelector('.menu__open-submenu');

      if (arrowMobile !== null) {
        arrowMobile.addEventListener('click', openMenuLevelTwoMobile);
      }
    }
  });
  if (window.innerWidth < 1320) submenuBtnBack.addEventListener("click", closeSubmenu);
  menuLevelTwoLinks.forEach((link) => {
    link.addEventListener('mouseenter', changeSubmenulevelThree);
    if (window.innerWidth < 1320) {
      const arrowMobile = link.parentElement.querySelector('.submenu__open-submenu');

      if (arrowMobile !== null) {
        arrowMobile.addEventListener('click', openMenuL3Mobile);
      }
    }
  });

  //* Открытие поиска
  const searchBtn = document.querySelector(".search-btn");
  const searchBlock = document.querySelector(".search");
  const searchClose = document.querySelector(".search__close");
  const searchBlockContainer = searchBlock.querySelector(".search__container");

  function toggleSearchHandler() {
    closeAll('search');
    if (!searchBlock.classList.contains("search_active")) {
      const openSearchTL = gsap.timeline();

      openSearchTL
        .to(searchBlock, {
          duration: 0.5,
          css: {
            transform: "scaleX(1)",
          },
        })
        .to(searchBlockContainer, {
          duration: 0.3,
          opacity: 1,
        });

      searchBlock.classList.add("search_active");
    } else {
      const closeSearchTL = gsap.timeline();

      closeSearchTL
        .to(searchBlockContainer, {
          duration: 0.3,
          opacity: 0,
        })
        .to(searchBlock, {
          duration: 0.5,
          css: {
            transform: "scaleX(0)",
          },
        });

      searchBlock.classList.remove("search_active");
    }
  }

  searchBtn.addEventListener("click", toggleSearchHandler);
  searchClose.addEventListener("click", toggleSearchHandler);

  //* Версия для слабовидящих
  let root = document.documentElement;
  const fzBtns = document.querySelectorAll(".visually-impaired-tools__icon_fz");
  const colorsBtns = document.querySelectorAll(".visually-impaired-tools__icon_color");
  const lsBtns = document.querySelectorAll(".visually-impaired-tools__icon_letter-space");
  const fontBtns = document.querySelectorAll(".visually-impaired-tools__icon_font");
  const imgBtns = document.querySelectorAll(".visually-impaired-tools__icon_img");
  const clearBtn = document.querySelector(".visually-impaired-tools__clear");
  const viBtn = document.querySelector(".visually-impaired-btn");
  const viBlock = document.querySelector(".visually-impaired-tools");
  const viBlockClose = viBlock.querySelector(".visually-impaired-tools__close");
  const VIConfig = JSON.parse(localStorage.getItem("visually-impaired")) || {
    fontSize: "",
    color: "",
    letterSpace: "",
    font: "",
    imgTurn: "",
  };

  if (!localStorage.getItem("visually-impaired")) {
    localStorage.setItem("visually-impaired", JSON.stringify(VIConfig));
  }

  function toggleVIBlock() {
    closeAll('vi');
    gsap.to(window, { duration: 1, scrollTo: 0 });
    viBlock.classList.toggle("visually-impaired-tools_active");
  }

  function closeVIBlock() {
    viBlock.classList.remove("visually-impaired-tools_active");
  }

  function loadVIMode() {
    changeFontSize(VIConfig.fontSize);
    changeColor(VIConfig.color);
    changeLetterSpacing(VIConfig.letterSpace);
    changeFont(VIConfig.font);
    toggleImgs(VIConfig.imgTurn);
  }

  function changeFontSize(fz) {
    if (fz !== "") {
      root.style.setProperty("--font-size", `${fz}em`);
    }
  }

  function changeColor(color) {
    switch (color) {
      case "normal": {
        enableNormalMode();
        break;
      }
      case "wb": {
        enableWBMode();
        break;
      }
      case "bw": {
        enableBWMode();
        break;
      }
      case "chrom": {
        enableChromMode();
        break;
      }
    }
  }

  function enableNormalMode() {
    document.body.style.backgroundColor = "#ffffff";
    root.style.setProperty("--bg-light", "#f1f8fb");
    root.style.setProperty("--bg-dark", "#111a26");
    root.style.setProperty("--bg", "#ffffff");
    root.style.setProperty("--bg-disabled", "#eeeeee");

    root.style.setProperty("--text-color", "#121212");
    root.style.setProperty("--text-color-light", "#ffffff");
    root.style.setProperty("--text-color-hover", "#008ad1");
    root.style.setProperty("--text-color-inactive", "#8d8d8d");
    root.style.setProperty("--text-color-disabled", "#808080");
    root.style.setProperty("--text-color-danger", "#f21f1f");
    root.style.setProperty("--link-color", "#008ad1");
    root.style.setProperty("--link-color-hover", "#115d85");

    root.style.setProperty("--btn-bg", "#008ad1");
    root.style.setProperty("--btn-bg-hover", "#115d85");
    root.style.setProperty("--btn-color", "#ffffff");
    root.style.setProperty("--btn-color-transparent", "#008ad1");

    document.querySelectorAll(".text_link").forEach((link) => {
      link.style.textDecoration = "none";
    });

    document.querySelectorAll("img").forEach((element) => {
      element.style.WebkitFilter = "grayscale(0%)";
      element.style.filter = "grayscale(0%)";
    });

    document.querySelectorAll(".logo").forEach((element) => {
      element.style.WebkitFilter = "grayscale(0%)";
      element.style.filter = "grayscale(0%)";
    });

    document.querySelectorAll(".important__text").forEach((text) => {
      text.style.color = "#121212";
    });

    document.querySelectorAll(".important__link").forEach((link) => {
      link.style.textDecoration = "none";
      link.style.color = "#008ad1";
    });
    document.querySelectorAll(".important__subtitle_blue").forEach((link) => {
      link.style.color = "#008ad1";
    });
    document.querySelectorAll(".important__table").forEach((table) => {
      table.style.borderColor = "#111a26";
      table.querySelector("thead").style.backgroundColor = "#111a26";
      table.querySelector("thead").style.color = "#ffffff";
      table.querySelector("tbody").style.setProperty("--bg-table-row", "#f1f8fb");
    });
    document.querySelectorAll(".titles-list__link").forEach((link) => {
      link.style.color = "#121212";
      link.querySelector("span").style.setProperty("--titles-list-link-span-color", "#008ad1");
    });
  }

  function enableWBMode() {
    document.body.style.backgroundColor = "#ffffff";
    root.style.setProperty("--bg-light", "#F2F2F2");
    root.style.setProperty("--bg-dark", "#ffffff");
    root.style.setProperty("--bg", "#ffffff");
    root.style.setProperty("--bg-disabled", "#eeeeee");

    root.style.setProperty("--text-color", "#000000");
    root.style.setProperty("--text-color-light", "#000000");
    root.style.setProperty("--text-color-hover", "#000000");
    root.style.setProperty("--text-color-inactive", "#8d8d8d");
    root.style.setProperty("--text-color-disabled", "#808080");
    root.style.setProperty("--text-color-danger", "#000000");
    root.style.setProperty("--link-color", "#000000");
    root.style.setProperty("--link-color-hover", "#000000");

    root.style.setProperty("--btn-bg", "#000000");
    root.style.setProperty("--btn-bg-hover", "#000000");
    root.style.setProperty("--btn-color", "#ffffff");
    root.style.setProperty("--btn-color-transparent", "#000000");

    document.querySelectorAll(".text_link").forEach((link) => {
      link.style.textDecoration = "underline";
    });

    document.querySelectorAll("img").forEach((element) => {
      element.style.WebkitFilter = "grayscale(100%)";
      element.style.filter = "grayscale(100%)";
    });

    document.querySelectorAll(".logo").forEach((element) => {
      element.style.WebkitFilter = "grayscale(100%)";
      element.style.filter = "grayscale(100%)";
    });

    document.querySelectorAll(".important__text").forEach((text) => {
      text.style.color = "#121212";
    });

    document.querySelectorAll(".important__link").forEach((link) => {
      link.style.textDecoration = "underline";
      link.style.color = "#000000";
    });
    document.querySelectorAll(".important__subtitle_blue").forEach((link) => {
      link.style.color = "#000000";
    });
    document.querySelectorAll(".important__table").forEach((table) => {
      table.style.borderColor = "#eeeeee";
      table.querySelector("thead").style.backgroundColor = "#eeeeee";
      table.querySelector("thead").style.color = "#000000";
      table.querySelector("tbody").style.setProperty("--bg-table-row", "#eeeeee");
    });
    document.querySelectorAll(".titles-list__link").forEach((link) => {
      link.style.color = "#000000";
      link.querySelector("span").style.setProperty("--titles-list-link-span-color", "#000000");
    });
  }

  function enableBWMode() {
    document.body.style.backgroundColor = "#000000";
    root.style.setProperty("--bg-light", "#333333");
    root.style.setProperty("--bg-dark", "#000000");
    root.style.setProperty("--bg", "#000000");
    root.style.setProperty("--bg-disabled", "#000000");

    root.style.setProperty("--text-color", "#ffffff");
    root.style.setProperty("--text-color-light", "#ffffff");
    root.style.setProperty("--text-color-hover", "#ffffff");
    root.style.setProperty("--text-color-inactive", "#8d8d8d");
    root.style.setProperty("--text-color-disabled", "#808080");
    root.style.setProperty("--text-color-danger", "#ffffff");
    root.style.setProperty("--link-color", "#ffffff");
    root.style.setProperty("--link-color-hover", "#ffffff");

    root.style.setProperty("--btn-bg", "#ffffff");
    root.style.setProperty("--btn-bg-hover", "#ffffff");
    root.style.setProperty("--btn-color", "#000000");
    root.style.setProperty("--btn-color-transparent", "#ffffff");

    document.querySelectorAll(".text_link").forEach((link) => {
      link.style.textDecoration = "underline";
    });

    document.querySelectorAll("img").forEach((element) => {
      element.style.WebkitFilter = "grayscale(100%)";
      element.style.filter = "grayscale(100%)";
    });

    document.querySelectorAll(".logo").forEach((element) => {
      element.style.WebkitFilter = "grayscale(100%)";
      element.style.filter = "grayscale(100%)";
    });

    document.querySelectorAll(".important__text").forEach((text) => {
      text.style.color = "#ffffff";
    });

    document.querySelectorAll(".important__link").forEach((link) => {
      link.style.textDecoration = "underline";
      link.style.color = "#ffffff";
    });
    document.querySelectorAll(".important__subtitle_blue").forEach((link) => {
      link.style.color = "#ffffff";
    });
    document.querySelectorAll(".important__table").forEach((table) => {
      table.style.borderColor = "#000000";
      table.querySelector("thead").style.backgroundColor = "#000000";
      table.querySelector("thead").style.color = "#ffffff";
      table.querySelector("tbody").style.setProperty("--bg-table-row", "#000000");
    });
    document.querySelectorAll(".titles-list__link").forEach((link) => {
      link.style.color = "#000000";
      link.querySelector("span").style.setProperty("--titles-list-link-span-color", "#000000");
    });
  }

  function enableChromMode() {
    document.body.style.backgroundColor = "#9dd1ff";
    root.style.setProperty("--bg-light", "#F1F8FB");
    root.style.setProperty("--bg-dark", "#9dd1ff");
    root.style.setProperty("--bg", "#9dd1ff");
    root.style.setProperty("--bg-disabled", "#9dd1ff");

    root.style.setProperty("--text-color", "#14305e");
    root.style.setProperty("--text-color-light", "#14305e");
    root.style.setProperty("--text-color-hover", "#14305e");
    root.style.setProperty("--text-color-inactive", "#9dd1ff");
    root.style.setProperty("--text-color-disabled", "#9dd1ff");
    root.style.setProperty("--text-color-danger", "#14305e");
    root.style.setProperty("--link-color", "#14305e");
    root.style.setProperty("--link-color-hover", "#14305e");

    root.style.setProperty("--btn-bg", "#14305E");
    root.style.setProperty("--btn-bg-hover", "#14305E");
    root.style.setProperty("--btn-color", "#ffffff");
    root.style.setProperty("--btn-color-transparent", "#14305e");

    document.querySelectorAll(".text_link").forEach((link) => {
      link.style.textDecoration = "underline";
    });

    document.querySelectorAll("img").forEach((element) => {
      element.style.WebkitFilter = "sepia(100%) hue-rotate(170deg) saturate(150%)";
      element.style.filter = "sepia(100%) hue-rotate(170deg) saturate(150%)";
    });

    document.querySelectorAll(".logo").forEach((element) => {
      element.style.WebkitFilter = "sepia(100%) hue-rotate(170deg) saturate(150%)";
      element.style.filter = "sepia(100%) hue-rotate(170deg) saturate(150%)";
    });

    document.querySelectorAll(".important__text").forEach((text) => {
      text.style.color = "#14305e";
    });

    document.querySelectorAll(".important__link").forEach((link) => {
      link.style.textDecoration = "underline";
      link.style.color = "#14305e";
    });
    document.querySelectorAll(".important__subtitle_blue").forEach((link) => {
      link.style.color = "#14305e";
    });
    document.querySelectorAll(".important__table").forEach((table) => {
      table.style.borderColor = "#9dd1ff";
      table.querySelector("thead").style.backgroundColor = "#9dd1ff";
      table.querySelector("thead").style.color = "#14305e";
      table.querySelector("tbody").style.setProperty("--bg-table-row", "#9dd1ff");
    });
    document.querySelectorAll(".titles-list__link").forEach((link) => {
      link.style.color = "#9dd1ff";
      link.querySelector("span").style.setProperty("--titles-list-link-span-color", "#9dd1ff");
    });
  }

  function changeLetterSpacing(ls) {
    if (ls !== "") {
      if (ls === "normal") {
        root.style.setProperty("--letter-spacing", ls);
      } else {
        root.style.setProperty("--letter-spacing", `${ls}px`);
      }
    }
  }

  function changeFont(font) {
    if (font !== "") {
      root.style.setProperty("--font-family", font);
    }
  }

  function toggleImgs(turn) {
    if (turn === "on") {
      document.querySelectorAll("img").forEach((element) => {
        element.style.opacity = "1";
      });

      document.querySelectorAll(".logo__svg").forEach((element) => {
        element.style.opacity = "1";
      });
    }

    if (turn === "off") {
      document.querySelectorAll("img").forEach((element) => {
        element.style.opacity = "0";
      });

      document.querySelectorAll(".logo__svg").forEach((element) => {
        element.style.opacity = "0";
      });
    }
  }

  function changeVIParamFZ(e) {
    const fz = e.target.dataset.fz;

    fzBtns.forEach((btn) => {
      btn.classList.remove("visually-impaired-tools__icon_active");
    });
    e.target.classList.add("visually-impaired-tools__icon_active");

    VIConfig.fontSize = fz;
    changeFontSize(fz);
    localStorage.setItem("visually-impaired", JSON.stringify(VIConfig));
  }

  function changeVIParamСolorMode(e) {
    const color = e.target.dataset.color;

    colorsBtns.forEach((btn) => {
      btn.classList.remove("visually-impaired-tools__icon_active");
    });
    e.target.classList.add("visually-impaired-tools__icon_active");

    VIConfig.color = color;
    changeColor(color);
    localStorage.setItem("visually-impaired", JSON.stringify(VIConfig));
  }

  function changeVIParamLS(e) {
    const ls = e.target.dataset.letterSpace;

    lsBtns.forEach((btn) => {
      btn.classList.remove("visually-impaired-tools__icon_active");
    });
    e.target.classList.add("visually-impaired-tools__icon_active");

    VIConfig.letterSpace = ls;
    changeLetterSpacing(ls);
    localStorage.setItem("visually-impaired", JSON.stringify(VIConfig));
  }

  function changeVIParamFont(e) {
    const font = e.target.dataset.font;

    fontBtns.forEach((btn) => {
      btn.classList.remove("visually-impaired-tools__icon_active");
    });
    e.target.classList.add("visually-impaired-tools__icon_active");

    VIConfig.font = font;
    changeFont(font);
    localStorage.setItem("visually-impaired", JSON.stringify(VIConfig));
  }

  function changeVIParamImg(e) {
    const img = e.target.dataset.img;

    imgBtns.forEach((btn) => {
      btn.classList.remove("visually-impaired-tools__icon_active");
    });
    e.target.classList.add("visually-impaired-tools__icon_active");

    VIConfig.imgTurn = img;
    toggleImgs(img);
    localStorage.setItem("visually-impaired", JSON.stringify(VIConfig));
  }

  function clearVI(e) {
    e.preventDefault();

    VIConfig.fontSize = "";
    VIConfig.color = "";
    VIConfig.letterSpace = "";
    VIConfig.font = "";
    VIConfig.imgTurn = "";

    localStorage.setItem("visually-impaired", JSON.stringify(VIConfig));

    window.location.reload();
  }

  viBtn.addEventListener("click", toggleVIBlock);
  viBlockClose.addEventListener("click", closeVIBlock);
  loadVIMode();

  fzBtns.forEach((fzBtn, index) => {
    fzBtn.addEventListener("click", changeVIParamFZ);
  });
  colorsBtns.forEach((colorsBtn, index) => {
    colorsBtn.addEventListener("click", changeVIParamСolorMode);
  });
  lsBtns.forEach((lsBtn, index) => {
    lsBtn.addEventListener("click", changeVIParamLS);
  });
  fontBtns.forEach((fontBtn, index) => {
    fontBtn.addEventListener("click", changeVIParamFont);
  });
  imgBtns.forEach((imgBtn, index) => {
    imgBtn.addEventListener("click", changeVIParamImg);
  });
  clearBtn.addEventListener("click", clearVI);

  //* Обратная связь
  const requestModal = document.querySelector(".request-modal");
  const requestModalClose = requestModal.querySelector(".modal__close");
  const requestConfirmModal = document.querySelector(".request-confirm-modal");
  const requestModalBody = requestModal.querySelector(".modal__body");
  const requestBtn = document.querySelector(".request-btn");
  const footerRequestBtn = document.querySelector(".footer__btn");
  const heroRequest = document.querySelector(".hero__request");
  const requestBg = requestModal.querySelector(".modal__bg");
  const requestForm = requestModal.querySelector(".request-form");
  const requestFormName = requestForm.querySelector(".request-form__name");
  const requestFormPhone = requestForm.querySelector(".request-form__phone");
  const requestFormEmail = requestForm.querySelector(".request-form__email");
  const requestFormText = requestForm.querySelector(".request-form__textarea");
  const requestModalDialog = new A11yDialog(requestModal);
  const requestConfirmModalDialog = new A11yDialog(requestConfirmModal);

  IMask(requestFormPhone, {
    mask: "+{7}(000)000-00-00",
  });

  function openRequestModal() {
    closeAll('modal');
    gsap.to(requestModal, {
      css: {
        opacity: 1,
        pointerEvents: "auto",
      },
    });
  }

  function closeRequestModal() {
    gsap.to(requestModal, {
      css: {
        opacity: 0,
        pointerEvents: "none",
      },
    });
  }

  function sendRequest(e) {
    e.preventDefault();

    if (checkFormValid()) {
      let url = "/feedback";
      axios({
        url: url,
        method: "POST",
        data: new FormData(requestForm),
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
      }).then(function (response) {
        // handle success
        if (response.data === "ok") {
          const tl = gsap.timeline();
          tl.to(requestModal, {
            css: {
              opacity: 0,
              pointerEvents: "none",
            },
          })
            .to(requestConfirmModal, {
              css: {
                opacity: 1,
                pointerEvents: "auto",
              },
            })
            .to(
              requestConfirmModal,
              {
                css: {
                  opacity: 0,
                  pointerEvents: "none",
                },
              },
              "+=10"
            );
        }
      });
    }
  }

  function checkFormValid() {
    let check = true;

    clearFormError();

    if (requestFormName.value === "") {
      check = false;
      requestFormName.classList.add("form__input_error");
      requestFormName.nextElementSibling.innerHTML = `Поле "Имя" не должно быть пустым`;
    }

    if (requestFormEmail.value === "") {
      check = false;
      requestFormEmail.classList.add("form__input_error");
      requestFormEmail.nextElementSibling.innerHTML = `Поле "Почта" не должно быть пустым`;
    } else if (!validateEmail(requestFormEmail.value)) {
      check = false;
      requestFormEmail.classList.add("form__input_error");
      requestFormEmail.nextElementSibling.innerHTML = `Неверно заполнено поле "Почта"`;
    }

    if (requestFormText.value === "") {
      check = false;
      requestFormText.classList.add("form__text_error");
      requestFormText.nextElementSibling.innerHTML = `Поле "Сообщение" не должно быть пустым`;
    }

    return check;
  }

  function validateEmail(email) {
    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(email);
  }

  function clearFormError() {
    requestFormName.classList.remove("form__input_error");
    requestFormName.nextElementSibling.innerHTML = ``;

    requestFormEmail.classList.remove("form__input_error");
    requestFormEmail.nextElementSibling.innerHTML = ``;

    requestFormText.classList.remove("form__textarea_error");
    console.dir(requestFormText);
    requestFormText.nextElementSibling.innerHTML = ``;
  }

  requestBtn.addEventListener("click", openRequestModal);
  if (heroRequest !== null) heroRequest.addEventListener("click", openRequestModal);
  if (footerRequestBtn !== null) footerRequestBtn.addEventListener("click", openRequestModal);
  requestBg.addEventListener("click", closeRequestModal);
  requestModalClose.addEventListener("click", closeRequestModal);
  requestForm.addEventListener("submit", sendRequest);
  requestFormName.addEventListener("focus", () => {
    requestFormName.classList.remove("form__input_error");
    requestFormName.nextElementSibling.innerHTML = ``;
  });

  requestFormEmail.addEventListener("focus", () => {
    requestFormEmail.classList.remove("form__input_error");
    requestFormEmail.nextElementSibling.innerHTML = ``;
  });

  requestFormText.addEventListener("focus", () => {
    requestFormText.classList.remove("form__textarea_error");
    requestFormText.nextElementSibling.innerHTML = ``;
  });

  //* Генрация кастомных выпадающих списков
  const selects = document.querySelectorAll(".form__select");

  function generateCustomSelect(select) {
    let customSelect = document.createElement("div");
    customSelect.classList.add("custom-select");

    if (select.classList.contains("form__select_disabled")) {
      customSelect.classList.add("custom-select_disabled");
    }

    let html = `
      <a href="#" class="custom-select__open text text_3">
        <span class="custom-select__open-text">${select[0].innerHTML}</span>
        <span class="custom-select__open-arrow"></span>
      </a>
      <div class="custom-select__options">
    `;
    for (let i = 1; i < select.options.length; i++) {
      html += `
        <div class="custom-select__option text text_3" data-value="${select.options[i].value}">${select.options[i].innerHTML}</div>
      `;
    }

    html += `
      </div>
    `;

    customSelect.innerHTML = html;

    select.parentNode.insertBefore(customSelect, select);
  }

  function openCustomSelect(e) {
    e.preventDefault();

    if (!e.target.parentElement.classList.contains("custom-select_disabled")) {
      document.querySelectorAll(".custom-select__open").forEach((element) => {
        if (element !== e.target) {
          element.classList.remove("custom-select__open_active");
          element.parentElement.classList.remove("custom-select_active");
        }
      });
      document.querySelectorAll(".custom-select__options").forEach((element) => {
        if (element !== e.target.nextElementSibling) {
          element.classList.remove("custom-select__options_active");
          element.parentElement.classList.remove("custom-select_active");
        }
      });

      e.target.classList.toggle("custom-select__open_active");
      e.target.parentElement.classList.toggle("custom-select_active");
      e.target.parentElement.classList.remove("custom-select_error");
      e.target.nextElementSibling.classList.toggle("custom-select__options_active");
      e.target.parentElement.nextElementSibling.nextElementSibling.innerHTML = "";
    }
  }

  function closeCustomSelect(customSelectOpen, customSelectOptions) {
    customSelectOpen.classList.remove("custom-select__open_active");
    customSelectOpen.parentElement.classList.remove("custom-select_active");
    customSelectOptions.classList.remove("custom-select__options_active");
  }

  function changeSelect(e) {
    e.preventDefault();
    let customSelectOpen = e.target.parentElement.previousElementSibling;
    let customSelectOptions = e.target.parentElement;
    let thisSelect = e.target.parentElement.parentElement.nextElementSibling;

    customSelectOpen.children[0].innerHTML = e.target.innerHTML;
    thisSelect.value = e.target.dataset.value;
    thisSelect.dispatchEvent(new Event("change"));

    closeCustomSelect(customSelectOpen, customSelectOptions);
  }

  if (selects.length !== 0) {
    selects.forEach((select) => {
      generateCustomSelect(select);
    });
    let customSelectsOpen = document.querySelectorAll(".custom-select__open");
    customSelectsOpen.forEach((customSelectOpen) => {
      customSelectOpen.addEventListener("click", openCustomSelect);
    });
  }

  document.addEventListener("click", (e) => {
    if (!e.target.closest(".custom-select")) {
      document.querySelectorAll(".custom-select__open").forEach((element) => {
        element.classList.remove("custom-select__open_active");
        element.parentElement.classList.remove("custom-select_active");
      });
      document.querySelectorAll(".custom-select__options").forEach((element) => {
        element.classList.remove("custom-select__options_active");
        element.parentElement.classList.remove("custom-select_active");
      });
    }

    if (e.target.closest(".custom-select__option")) {
      changeSelect(e);
    }
  });

  const closeAll = (target) => {
    if (target !== 'modal') {
      closeRequestModal();
    }

    if (target !== 'search') {
      const closeSearchTL = gsap.timeline();

      closeSearchTL
        .to(searchBlockContainer, {
          duration: 0.3,
          opacity: 0,
        })
        .to(searchBlock, {
          duration: 0.5,
          css: {
            transform: "scaleX(0)",
          },
        });

      searchBlock.classList.remove("search_active");
    }

    if (target !== 'menu') {
      closeMenu();
    }

    if (target !== 'vi') {
      closeVIBlock()
    }

  }
});
